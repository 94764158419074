<template>
  <div class="producttype-wrap">
    <el-form
      class="search-pannel"
      ref="form"
      size="small"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="产品类型：">
        <el-input v-model="searchForm.productType" placeholder="请输入产品类型"></el-input>
      </el-form-item>
      <el-form-item label="编码：">
        <el-input v-model="searchForm.productCode" placeholder="请输入编码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="success" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>
    <!-- <el-row>
      <el-button type="primary" size="small" @click="newlyAdded('add')">新增</el-button>
    </el-row> -->
    <Table
      style="margin-top: 30px"
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @pagination="updatePage"
    >
      <!-- <el-table-column slot="operate" label="操作" width="150" fixed="right">
        <template #default="scope">
          <el-button type="text" size="small" @click="handleClickSee(scope.row)">查看</el-button>
          <el-button type="text" size="small" @click="handleClickDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column> -->
    </Table>
    <Dialog width="35%" :title="dlogstatus == 'add' ? '新增' : '编辑'" @close="closeDialog" :visible="visible">
      <div class="form-dig-wrap">
        <el-form class="form-content" ref="ruleForm" label-width="120px" :model="ruleForm" :rules="rules">
          <el-form-item label="编码：" prop="productCode">
            <el-input v-model="ruleForm.productCode"></el-input>
          </el-form-item>
          <el-form-item label="产品类型：" prop="productType">
            <el-input v-model="ruleForm.productType"></el-input>
          </el-form-item>
          <el-form-item label="加密类型：" prop="encryptType">
            <el-select v-model="ruleForm.encryptType" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="认证模式：" prop="authenticationMode">
            <el-select v-model="ruleForm.authenticationMode" placeholder="请选择">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="submitCreatedType">确定</el-button>
            <el-button @click="visible = false">取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Dialog from '@/components/Dialog'
import { dateFormatter } from '@/utils'
/* v1.0不做新增&编辑&修改 */
import { getProductList, createProduct, updateProduct, deleteProduct } from '@/api/modules/cabinet'
export default {
  name: 'productType',
  components: {
    Table,
    Dialog
  },
  data() {
    return {
      searchForm: {
        productType: '',
        productCode: ''
      },
      ruleForm: {
        productCode: '',
        productType: '',
        authenticationMode: '',
        encryptType: ''
      },
      dlogstatus: '',
      columns: [
        { label: '编码', prop: 'productCode' },
        { label: '产品类型', prop: 'productType' },
        { label: '加密类型', prop: 'encryptType' },
        { label: '认证模式', prop: 'authenticationMode' },
        { label: '密钥', prop: 'productKey' },
        { label: '创建人', prop: 'createUser' },
        { label: '创建时间', prop: 'createSysTm' }
      ],
      tableData: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      visible: false,
      tableLoading: false,
      dialogTitle: '新增'
    }
  },
  created() {
    this.onSearch(true)
  },
  computed: {
    rules() {
      return {
        productCode: [{ required: true, message: '请输入编码名称', trigger: 'blur' }],
        productType: [{ required: true, message: '请输入产品类型', trigger: 'blur' }],
        encryptType: [{ required: true, message: '请选择加密类型', trigger: 'change' }],
        authenticationMode: [{ required: true, message: '请选择认证模式', trigger: 'change' }]
      }
    }
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    // 获取table列表
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getProductList(data)
        .then((res) => {
          const { records, pageNum, pageSize, total = 0 } = res.data
          this.tableData = records || []
          this.tableData.forEach((item) => {
            item.createSysTm = dateFormatter(new Date(item.createSysTm))
          })
          this.pageParams = { pageNum, pageSize, total }
          console.log('getProductList-res: ', res)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onReset(formName) {
      this.searchForm = {
        productType: '',
        productCode: ''
      }
      this.$refs[formName].resetFields()
    },
    // 提交新增&编辑验证
    submitCreatedType() {
      const data = this.ruleForm
      this.$refs.ruleForm.validate(async(valid) => {
        if (!valid) return
        if (this.dlogstatus === 'add') {
          this.handleaddExit(createProduct, data)
        } else {
          this.handleaddExit(updateProduct, data)
        }
      })
    },
    // 处理新增&编辑接口
    handleaddExit(fn, data) {
      fn(data).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.visible = false
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    newlyAdded(type) {
      console.log('newlyAdded: ')
      this.dlogstatus = type
      this.visible = true
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    handleClickSee(row) {
      console.log('handleClickSee: ', row)
    },
    handleClickDelete(row) {
      console.log('handleClickDelete: ', row)
      this.$confirm('确认要删除吗？', '确认')
        .then(async() => {
          const result = await deleteProduct({ userId: row.userId })
          if (result.code === '000000000') {
            this.$message.success(result.msg)
            this.onSearch()
          } else {
            this.$message.warning(result.msg)
          }
        })
        .catch()
    },
    closeDialog() {
      console.log('productType-userManage: ', this.$refs)
      this.$refs.ruleForm.resetFields()
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.producttype-wrap {
  padding: 24px;
}
.form-dig-wrap {
  .el-input {
    width: 280px;
  }
  .form-content {
    width: 450px;
    margin: 0 auto;
  }
}
</style>
